document.addEventListener("DOMContentLoaded", function () {
  if (!Drupal.settings.safe_triangle?.enabled || !Drupal.settings.safe_triangle?.submenuSelector) {
    return false;
  }

  const menuItems = document.querySelectorAll(`${Drupal.settings.safe_triangle.submenuSelector}`);

  const updateSafeTargetArea = (event) => {
    requestAnimationFrame(() => {
      event.preventDefault();
      const safeTriangleElement = document.querySelector('.js-safe-triangle');
      const { clientX, clientY, target } = event;
      const sourceRectangle = (target)?.getBoundingClientRect();

      if (
        clientX > sourceRectangle.left &&
        clientX < sourceRectangle.right &&
        clientY > sourceRectangle.top &&
        clientY < sourceRectangle.bottom
      ) {
        safeTriangleElement &&
          (safeTriangleElement.style.clipPath = `polygon(${((sourceRectangle.x + event.offsetX) / window.innerWidth) * 100
            }% 0, 100% 100%, 0 100%)`);
      }
    });
  };

  const handleMouseOver = () => {
    document.body.addEventListener('mousemove', updateSafeTargetArea);
  };

  const handleMouseLeave = () => {
    document.body.removeEventListener('mousemove', updateSafeTargetArea);
  };

  if (menuItems.length > 0) {
    const firstChild = menuItems[0];
    const menu = firstChild.parentNode;

    const safeArea = document.createElement("span");
    safeArea.className = 'js-safe-triangle';
    safeArea.style.height = `${menu.clientHeight}px`;
    safeArea.style.top = `${menu.clientHeight / 2}px`;
    safeArea.style.zIndex = 10;

    firstChild.parentNode.prepend(safeArea);

    menuItems.forEach(menuItem => {
      menuItem.addEventListener('mouseover', handleMouseOver);
      menuItem.addEventListener('mouseout', handleMouseLeave);
    });
  }
})
